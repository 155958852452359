<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<section class="header {{ headerSectionCss() || '' }}">
  <div class="header__container">
    <div class="header__logo__wave header__logo__wave-svg">
      <div class="img-change"></div>
      <div class="img"></div>
    </div>
    <div class="header__navigation sticky">
      <div class="header__logo">
        <a
          routerLink="/"
          routerLinkActive="router-link-active"
          id="stickylogo"
          aria-label="ashutec logo"
          ><img src="assets/images/logo.svg" alt="ashutec logo"
        /></a>
      </div>
      <div class="header__item">
        <div
          class="menu-toggle"
          id="hamburger"
          (click)="isMobileMenuOpened = true; onMenuClick()"
        >
          <i class="fas fa-bars" id="stickymenu"></i>
        </div>
        <div class="overlay"></div>
        <nav (click)="onMenuClick(); isMobileMenuOpened = false">
          <ul>
            <li>
              <a
                routerLink="/"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                class="hover-underline-animation"
              >
                <h5>Home</h5>
              </a>
            </li>
            <li>
              <a
                routerLink="services"
                routerLinkActive="active"
                class="hover-underline-animation"
              >
                <h5>Services</h5>
              </a>
            </li>
            <li>
              <a
                routerLink="our-work"
                routerLinkActive="active"
                class="hover-underline-animation"
              >
                <h5>Our Work</h5>
              </a>
            </li>
            <li>
              <a
                routerLink="about-us"
                routerLinkActive="active"
                class="hover-underline-animation"
              >
                <h5>About us</h5>
              </a>
            </li>
            <li>
              <a
                routerLink="blog"
                routerLinkActive="active"
                class="hover-underline-animation"
              >
                <h5>Blog</h5>
              </a>
            </li>
            <li>
              <a
                routerLink="testimonials"
                routerLinkActive="active"
                class="hover-underline-animation"
              >
                <h5>Testimonials</h5>
              </a>
            </li>
            <li>
              <a
                routerLink="career"
                routerLinkActive="active"
                class="hover-underline-animation"
              >
                <h5>Career</h5>
              </a>
            </li>
            <li>
              <a
                routerLink="contact-us"
                routerLinkActive="active"
                class="hover-underline-animation"
              >
                <h5>Contact us</h5>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</section>
