import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appScrollToTop]',
  standalone: true,
})
export class ScrollToTopDirective {
  @HostListener('click')
  public onClick() {
    if (window && window.scrollY) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }
  @HostListener('window:scroll', ['$event']) track($event) {
    if ($event) {
      const element = $event.target;
      const body = element.getElementsByTagName('html')[0] as Element;
      const scrollToButton = element.getElementById('return-to-top');
      if (scrollToButton) {
        if (body.scrollTop > 380) {
          scrollToButton.style.display = 'inline';
        } else {
          scrollToButton.style.display = 'none';
        }
      }
    }
  }
}
