import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  PLATFORM_ID,
  Renderer2,
  signal,
} from '@angular/core';
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import anime from 'animejs/lib/anime.es.js';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { WindowService } from 'src/app/shared/services';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink, RouterLinkActive],
})
export class HeaderComponent {
  menu: HTMLElement;
  changeIcon = true;
  isMobileMenuOpened = false;
  headerSectionCss = signal('');
  subscriptions: Subscription[] = [];
  constructor(
    private windowService: WindowService,
    private element: ElementRef,
    private render: Renderer2,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    if (isPlatformBrowser(platformId)) {
      this.windowService.windowRef.addEventListener(
        'scroll',
        this.doAnimations,
      );
      this.doAnimations();
      this.subscriptions.push(
        this.router.events
          .pipe(
            filter((event) => event instanceof NavigationEnd),
            tap((event: NavigationEnd) => {
              if (event.url.includes('/services/')) {
                this.headerSectionCss.set('black');
              } else {
                this.headerSectionCss.set('');
              }

              if (
                (event.url.includes('/blog/') &&
                  !event.url.includes('/blog/page')) ||
                event.url.includes('/terms') ||
                event.url.includes('/privacy-policy')
              ) {
                this.menu.classList.add('sticky');
                this.menu.style.boxShadow = '0 0.22vw 0.8vw #00000026';
                this.menu.style.background = '#fff';
                this.setHeaderSticky();
              } else {
                this.menu.style.boxShadow = 'none';
                this.menu.style.background = 'transparent';
                this.removeHeaderSticky();
              }
            }),
          )
          .subscribe(),
      );
    }
  }

  setHeaderSticky() {
    anime({
      targets: '.img-change',
      opacity: '1',
      duration: 100,
      begin: () => {
        this.document
          .querySelector('.img-change')
          .setAttribute('style', 'display:block;');
      },
    });
    this.document.querySelector('#stickymenu').classList.add('stickymenu');
    this.document.querySelector('#stickylogo').classList.add('stickylogo');
    this.document.querySelectorAll('nav ul li a h5').forEach((item) => {
      item.classList.add('font-drop');
    });
  }

  removeHeaderSticky() {
    anime({
      targets: '.img-change',
      opacity: '0',
      duration: 100,
      complete: () => {
        document
          .querySelector('.img-change')
          .setAttribute('style', 'display:none;');
      },
    });
    this.document.querySelector('#stickymenu').classList.remove('stickymenu');
    this.document.querySelector('#stickylogo').classList.remove('stickylogo');
    this.document.querySelectorAll('nav ul li a h5').forEach((item) => {
      item.classList.remove('font-drop');
    });
  }

  doAnimations = () => {
    this.menu = this.document.querySelector(
      '.header__navigation',
    ) as HTMLElement;
    const origTopCoordinateMenu = this.menu.offsetTop;
    // eslint-disable-next-line max-len
    if (
      (!this.router.url.startsWith('/blog/') &&
        !this.router.url.startsWith('/terms') &&
        !this.router.url.startsWith('/privacy-policy')) ||
      this.router.url.startsWith('/blog/page/')
    ) {
      if (this.windowService.windowRef.scrollY > origTopCoordinateMenu) {
        this.menu.classList.add('sticky');
        anime({
          targets: '.img',
          height: 0,
        });

        this.setHeaderSticky();
      } else {
        anime({
          targets: '.img',
          height: '19vh',
        });
        this.removeHeaderSticky();
      }
    }
  };

  onMenuClick() {
    const dom: HTMLElement = this.element.nativeElement;
    window.addEventListener('scroll', () => {
      if (this.windowService.windowRef.scrollY > 250) {
        dom.querySelector('.header').classList.add('active');
      }
    });

    const overlay = dom.querySelector('.overlay');
    const nav = dom.querySelector('nav');
    const icon = dom.querySelector('.menu-toggle i');
    overlay.classList.toggle('menu-open');
    nav.classList.toggle('menu-open');

    if (this.changeIcon && this.isMobileMenuOpened) {
      icon.classList.remove('fa-bars');
      icon.classList.add('fa-times');
      // body.classList.add("mobile-nav");
      this.render.addClass(this.document.body, 'mobile-nav');
      this.changeIcon = false;
    } else {
      icon.classList.remove('fa-times');
      icon.classList.add('fa-bars');
      // body.classList.remove("mobile-nav");
      this.render.removeClass(document.body, 'mobile-nav');
      this.changeIcon = true;
    }
  }
}
