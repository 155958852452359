<div
  class="cookie-law-wrapper"
  [ngStyle]="currentStyles"
  [@transition]="transition"
  (@transition.done)="afterDismissAnimation($event)"
>
  <div class="copy mini-container">
    <span #ref><ng-content></ng-content></span>

    <span *ngIf="ref.childNodes.length === 0">
      By continuing to browse the site, you're agreeing to our use of cookies.
      <span *ngIf="learnMore">
        Learn more in our
        <a
          [href]="learnMore"
          [target]="target"
          [rel]="noopener ? 'noopener noreferrer' : null"
          >privacy policy</a
        >.
      </span>
    </span>
  </div>
</div>
