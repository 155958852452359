<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<app-header></app-header>
<ng-container appScrollDoAnimation
  ><router-outlet></router-outlet
></ng-container>
@if (social()) {
  <app-footer
    [social]="social()"
    [callToAction]="footerCallToAction()"
  ></app-footer>
}
@if (cookies()) {
  <app-cookie-law #cookieLaw [expiration]="expirationTime">
    <span class="footer-cookie">
      <div>
        We use cookies to provide our services. To find out more about our use
        of cookies, please see our
        <a routerLink="/privacy-policy" target="_blank">Privacy Policy</a>. By
        continuing to browse our website, you agree to our use of cookies.
      </div>
      <div>
        <button class="btn btn-bordered" (click)="cookiesAccepted()">
          Accept
        </button>
      </div>
    </span>
  </app-cookie-law>
}

<a href="javascript:" appScrollToTop id="return-to-top" style="display: none"
  ><i class="fa fa-arrow-up"></i
></a>

<!-- <a href="javascript:" id="chat"><i class="fa fa-comment"></i></a> -->
<a id="share" (click)="onClickShare()"
  ><i #shareIcon class="fa fa-share-alt"></i
></a>
<div class="social--buttons social-buttons soc_btn_up_down" #shareButtons>
  <share-buttons
    theme="circles-dark"
    [include]="[
      'facebook',
      'twitter',
      'linkedin',
      'telegram',
      'messenger',
      'whatsapp',
      'xing',
      'email'
    ]"
    [url]="shareUrl()"
    [autoSetMeta]="true"
  ></share-buttons>
</div>
