import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Directive,
  HostListener,
  Inject,
  PLATFORM_ID,
  effect,
  inject,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router, Scroll } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
@UntilDestroy({ arrayName: 'subscriptions' })
@Directive({
  selector: '[appScrollDoAnimation]',
  standalone: true,
})
export class ScrollDoAnimationDirective {
  subscriptions: Subscription[] = [];
  scroll = toSignal(
    inject(Router).events.pipe(filter((event) => event instanceof Scroll)),
  );
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    effect(() => {
      if (isPlatformBrowser(platformId) && this.scroll()) {
        setTimeout(() => this.doAnimations());
      }
    });
  }

  @HostListener('window:scroll', ['$event']) onScroll() {
    this.doAnimations();
  }

  private doAnimations() {
    const animaTables = this.document.querySelectorAll('.animatable');
    if (animaTables.length > 0) {
      animaTables.forEach((el: Element) => {
        const box = el.getBoundingClientRect();
        // This checks only animate elements which are visible on screen/view port
        // otherwise all elements would be animated even if those are not on screen
        if (box.top <= window.innerHeight) {
          el.classList.remove('animatable');
          el.classList.add('animated');
        }
      });
    }
  }
}
