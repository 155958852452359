import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.routes'),
  },
  {
    path: 'services',
    loadChildren: () => import('./services/services.routes'),
  },
  {
    path: 'our-work',
    loadChildren: () => import('./portfolio/portfolio-page.routes'),
  },
  {
    path: 'about-us',
    loadChildren: () => import('./about-us/about-us.routes'),
  },
  {
    path: 'blog',
    loadChildren: () => import('./blog/blog.routes'),
  },
  {
    path: 'testimonials',
    loadChildren: () => import('./testimonials/testimonials.routes'),
  },
  {
    path: 'career',
    loadChildren: () => import('./career/career.routes'),
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./contact-us/contact-us.routes'),
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy-policy/privacy-policy.routes'),
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms-of-use/terms-of-use.routes'),
  },
  {
    path: '404',
    loadChildren: () => import('./page-not-found/pageNotFound.routes'),
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];
