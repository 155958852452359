<!-- ************* Footer ************* -->
@switch (displayFooter()) {
  @case ("About-us") {
    <section class="bg-dark new_bg">
      <div class="quote main-container animatable fadeinup">
        <div class="main-title text-center">
          <h1 class="large">{{ callToAction()?.title }}</h1>
          <h4>{{ callToAction()?.description }}</h4>
          <div class="button-group">
            <!-- <a routerLink="/career" class="btn btn-bordered">Explore Opportunities</a> -->
            <a
              routerLink="/career"
              [state]="{ scrollTo: 'getIn' }"
              class="btn btn-bordered"
              >Explore Opportunities</a
            >
          </div>

          <!-- ************* Footer ************* -->
          <div class="footer second">
            <div class="width-3">
              <h2>Quick access</h2>
              <ul class="menu">
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="our-work">Our Work</a></li>
                <li><a routerLink="about-us">About ashutec</a></li>
                <li><a routerLink="blog">Blog</a></li>
                <li><a routerLink="testimonials">Testimonials</a></li>
              </ul>
            </div>

            <div class="width-3">
              <h2>Quick access</h2>
              <ul class="menu">
                <li><a routerLink="career">Career</a></li>
                <li><a routerLink="contact-us">Contact Us</a></li>
                <li><a routerLink="terms">Terms of Use</a></li>
                <li><a routerLink="privacy-policy">Privacy policy</a></li>
              </ul>
            </div>

            <div class="width-3">
              <h2>Services</h2>
              <ul class="menu">
                <li>
                  <a routerLink="services/web-application-development-services"
                    >Web Applications</a
                  >
                </li>
                <li>
                  <a routerLink="services/mobile-app-development-services"
                    >Mobile Applications</a
                  >
                </li>
                <li>
                  <a routerLink="services/api-development-services"
                    >API Development</a
                  >
                </li>
                <li><a routerLink="services/ui-ux-design">UI/UX Design</a></li>
                <li>
                  <a routerLink="services/software-testing-services"
                    >Software Testing</a
                  >
                </li>
                <li>
                  <a routerLink="services/app-upgradation-and-migration-service"
                    >App Upgradation and Migration</a
                  >
                </li>
              </ul>
            </div>

            <div class="width-3">
              <h2>{{ social()?.title }}</h2>
              <h5>
                {{ social()?.description }}
              </h5>
              <ul class="footer__social">
                <li>
                  <a
                    href="{{ social()?.links.in.url }}"
                    target="{{ social()?.links.in.target }}"
                    title="Linkedin"
                    ><span class="icon fa fa-linkedin"></span
                  ></a>
                </li>
                <li>
                  <a
                    href="{{ social()?.links.tw.url }}"
                    target="{{ social()?.links.tw.target }}"
                    title="Twitter"
                  >
                    <span class="icon fa-brands fa fa-x-twitter"> </span
                  ></a>
                </li>
                <li>
                  <a
                    href="{{ social()?.links.fb.url }}"
                    target="{{ social()?.links.fb.target }}"
                    title="Facebook"
                    ><span class="icon fa fa-facebook"></span
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="footer__bottom second">
      <div class="main-container">
        <div class="footer__bottom__copyright text-center">
          <h5 class="mar-0">
            ©{{ currentYear }} Ashutec Solutions Pvt Ltd, All Right Reserved
          </h5>
          <h6 class="mar-0">
            all logos and trademarks are property of their respective owners
          </h6>
        </div>
      </div>
    </div>
  }
  @case ("Details-Page-Footer") {
    <section
      class="bg-dark new_bg service_detail_page {{
        detailsPageFooterSectionDetail()?.sectionCss
      }}"
    >
      <div class="quote main-container animatable fadeinup">
        <div class="main-title text-center">
          <h1 class="large">{{ callToAction()?.title }}</h1>
          <h4>{{ callToAction()?.description }}</h4>
          <div class="button-group">
            <a
              [routerLink]="['/contact-us']"
              [queryParams]="{ section: 'getInTouch' }"
              class="btn btn-bordered {{
                detailsPageFooterSectionDetail().btnCss
              }}"
              >Get a Quote</a
            >
          </div>

          <!-- ************* Footer ************* -->
          <div class="footer second">
            <div class="width-3">
              <h2>Quick access</h2>
              <ul class="menu">
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="our-work">Our Work</a></li>
                <li><a routerLink="about-us">About ashutec</a></li>
                <li><a routerLink="blog">Blog</a></li>
                <li><a routerLink="testimonials">Testimonials</a></li>
              </ul>
            </div>

            <div class="width-3">
              <h2>Quick access</h2>
              <ul class="menu">
                <li><a routerLink="career">Career</a></li>
                <li><a routerLink="contact-us">Contact Us</a></li>
                <li><a routerLink="terms">Terms of Use</a></li>
                <li><a routerLink="privacy-policy">Privacy policy</a></li>
              </ul>
            </div>

            <div class="width-3">
              <h2>Services</h2>
              <ul class="menu">
                <li>
                  <a routerLink="services/web-application-development-services"
                    >Web Applications</a
                  >
                </li>
                <li>
                  <a routerLink="services/mobile-app-development-services"
                    >Mobile Applications</a
                  >
                </li>
                <li>
                  <a routerLink="services/api-development-services"
                    >API Development</a
                  >
                </li>
                <li><a routerLink="services/ui-ux-design">UI/UX Design</a></li>
                <li>
                  <a routerLink="services/software-testing-services"
                    >Software Testing</a
                  >
                </li>
                <li>
                  <a routerLink="services/app-upgradation-and-migration-service"
                    >App Upgradation and Migration</a
                  >
                </li>
              </ul>
            </div>

            <div class="width-3">
              <h2>{{ social()?.title }}</h2>
              <h5>
                {{ social()?.description }}
              </h5>

              <ul class="footer__social">
                <li>
                  <a
                    href="{{ social()?.links.in.url }}"
                    target="{{ social()?.links.in.target }}"
                    title="Linkedin"
                    ><span class="icon fa fa-linkedin"></span
                  ></a>
                </li>
                <li>
                  <a
                    href="{{ social()?.links.tw.url }}"
                    target="{{ social()?.links.tw.target }}"
                    title="Twitter"
                  >
                    <span class="icon fa-brands fa fa-x-twitter"> </span
                  ></a>
                </li>
                <li>
                  <a
                    href="{{ social()?.links.fb.url }}"
                    target="{{ social()?.links.fb.target }}"
                    title="Facebook"
                    ><span class="icon fa fa-facebook"></span
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Footer Bottom -->
    <div class="footer__bottom second">
      <div class="main-container">
        <div class="footer__bottom__copyright text-center">
          <h5 class="mar-0">
            ©{{ currentYear }} Ashutec Solutions Pvt Ltd, All Right Reserved
          </h5>
          <h6 class="mar-0">
            all logos and trademarks are property of their respective owners
          </h6>
        </div>
      </div>
    </div>
  }
  @default {
    <section class="footer">
      <div class="main-container pb-80">
        <div class="width-3">
          <h2>Quick access</h2>
          <ul class="menu">
            <li><a routerLink="/">Home</a></li>
            <li><a routerLink="our-work">Our Work</a></li>
            <li><a routerLink="about-us">About ashutec</a></li>
            <li><a routerLink="blog">Blog</a></li>
            <li><a routerLink="testimonials">Testimonials</a></li>
          </ul>
        </div>

        <div class="width-3">
          <h2>Quick access</h2>
          <ul class="menu">
            <li><a routerLink="career">Career</a></li>
            <li><a routerLink="contact-us">Contact Us</a></li>
            <li><a routerLink="terms">Terms of Use</a></li>
            <li><a routerLink="privacy-policy">Privacy policy</a></li>
          </ul>
        </div>

        <div class="width-3">
          <h2>Services</h2>
          <ul class="menu">
            <li>
              <a routerLink="services/web-application-development-services"
                >Web Applications</a
              >
            </li>
            <li>
              <a routerLink="services/mobile-app-development-services"
                >Mobile Applications</a
              >
            </li>
            <li>
              <a routerLink="services/api-development-services"
                >API Development</a
              >
            </li>
            <li><a routerLink="services/ui-ux-design">UI/UX Design</a></li>
            <li>
              <a routerLink="services/software-testing-services"
                >Software Testing</a
              >
            </li>
            <li>
              <a routerLink="services/app-upgradation-and-migration-service"
                >App Upgradation and Migration</a
              >
            </li>
          </ul>
        </div>

        <div class="width-3">
          <h2>{{ social()?.title }}</h2>
          <h5>
            {{ social()?.description }}
          </h5>
          <ul class="footer__social">
            <li>
              <a
                href="{{ social()?.links.in.url }}"
                target="{{ social()?.links.in.target }}"
                title="Linkedin"
                ><span class="icon fa fa-linkedin"></span
              ></a>
            </li>
            <li>
              <a
                href="{{ social()?.links.tw.url }}"
                target="{{ social()?.links.tw.target }}"
                title="Twitter"
              >
                <span class="icon fa-brands fa fa-x-twitter"> </span
              ></a>
            </li>
            <li>
              <a
                href="{{ social()?.links.fb.url }}"
                target="{{ social()?.links.fb.target }}"
                title="Facebook"
                ><span class="icon fa fa-facebook"></span
              ></a>
            </li>
          </ul>
        </div>
      </div>

      <!-- Footer Bottom -->
      <div class="footer__bottom">
        <div class="main-container">
          <div class="footer__bottom__copyright text-center">
            <h5 class="mar-0">
              ©{{ currentYear }} Ashutec Solutions Pvt Ltd, All Right Reserved
            </h5>
            <h6 class="mar-0">
              all logos and trademarks are property of their respective owners
            </h6>
          </div>
        </div>
      </div>
    </section>
  }
}
