import {
  ChangeDetectionStrategy,
  Component,
  input,
  signal,
} from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { IContent, ISocialSection } from 'shared/models';
@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink],
})
export class FooterComponent {
  social = input.required<ISocialSection>();
  callToAction = input.required<IContent>();
  subscriptions: Subscription[] = [];
  displayFooter = signal('Default');
  detailsPageFooterSectionDetail = signal({
    sectionCss: 'project',
    btnCss: 'project-btn',
  });
  currentYear: number;

  constructor(public router: Router) {
    this.subscriptions.push(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          tap((event: NavigationEnd) => {
            if (event.url === '/about-us') {
              this.displayFooter.set('About-us');
            } else if (
              event.url.includes('/our-work/') ||
              event.url.includes('/services/')
            ) {
              this.displayFooter.set('Details-Page-Footer');
              if (event.url.includes('/services/')) {
                this.detailsPageFooterSectionDetail.set({
                  sectionCss: '',
                  btnCss: '',
                });
              } else {
                this.detailsPageFooterSectionDetail.set({
                  sectionCss: 'project',
                  btnCss: 'project-btn',
                });
              }
            } else {
              this.displayFooter.set('Default');
            }
          }),
        )
        .subscribe(),
    );
    const currentDate = new Date();
    this.currentYear = currentDate.getFullYear();
  }
}
