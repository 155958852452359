/**
 * angular2-cookie-law
 *
 * Copyright 2016-2018, @andreasonny83, All rights reserved.
 *
 * @author: @andreasonny83 <andreasonny83@gmail.com>
 */

export const closeIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M377.047 184.198q0 8.26-6.037 14.297L313.505 256l57.505 57.505q6.037 6.037 6.037 14.297 0 8.578-6.037
      14.615l-28.593 28.593q-6.037 6.037-14.615 6.037-8.26 0-14.297-6.037L256 313.505l-57.505 57.505q-6.037
      6.037-14.297 6.037-8.578 0-14.615-6.037l-28.593-28.593q-6.037-6.037-6.037-14.615 0-8.26 6.037-14.297L198.495
      256l-57.505-57.505q-6.037-6.037-6.037-14.297 0-8.578 6.037-14.615l28.593-28.593q6.037-6.037 14.615-6.037 8.26 0
      14.297 6.037L256 198.495l57.505-57.505q6.037-6.037 14.297-6.037 8.578 0 14.615 6.037l28.593 28.593q6.037 6.037
      6.037 14.615zM500 256q0-66.401-32.724-122.477-32.724-56.075-88.799-88.799Q322.401 12 256 12q-66.401 0-122.477
      32.724-56.075 32.724-88.799 88.799Q12 189.599 12 256q0 66.401 32.724 122.477 32.724 56.075 88.799 88.799Q189.599
      500 256 500q66.401 0 122.477-32.724 56.075-32.724 88.799-88.799Q500 322.401 500 256z"></path>
  </svg>`;
